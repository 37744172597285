import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="simple-message">
      <h1>Page not found.</h1>
    </div>
  </Layout>
)

export default NotFoundPage
